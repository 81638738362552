<template>
  <div>
    <div class="main-title row">
      <div class="title">活动：{{ detailInfo.activity_name || '-' }}</div>
      <div class="time">
        <span>更新时间：{{ detailInfo.mtime || '-' }}</span>
      </div>
    </div>
    <div class="content">
      <div class="box row">
        <div class="col row">
          <div class="title">品牌：</div>
          <div class="txt">{{ detailInfo.principal_name || '-' }}</div>
        </div>
        <div class="col row">
          <div class="title">活动时间：</div>
          <div class="txt">
            {{ detailInfo.start_time || '-' }} ~
            {{ detailInfo.end_time || '-' }}
          </div>
        </div>
      </div>
      <div class="box">
        <div class="title">统计范围：</div>
        <div class="detail">
          <div class="txt row" v-if="detailInfo.is_video">
            视频<span class="mr-20"></span>标题{{ getRelationType(detailInfo.video_keyword_relation) }}关键词：{{
              detailInfo.other_video_key_word || '-'
            }}
          </div>
          <div class="txt row" v-if="detailInfo.is_live">
            直播<span class="mr-20"></span>标题{{ getRelationType(detailInfo.live_keyword_relation) }}关键词：{{
              detailInfo.other_live_key_word || '-'
            }}
          </div>
          <div class="txt row" v-if="detailInfo.is_note">
            笔记<span class="mr-20"></span>{{ getNoteType(detailInfo) }}关键词：{{ getNoteKeyword(detailInfo) || '-' }}
          </div>
        </div>
      </div>
      <div class="box">
        <div class="title">活动平台：</div>
        <div class="detail">{{ detailInfo.platform_text || '-' }}</div>
      </div>
      <div class="box">
        <div class="title">关联模板：<a-icon type="form" class="icon" @click="onShowDrawer" /></div>
        <div class="detail">
          <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            row-key="(record, index) => index"
            :pagination="false"
            class="table"
          >
            <div slot="action" slot-scope="text, record">
              <a @click="openSampleUrl(record.id)">查看样片</a>
              <a @click="deleteGuideShoot(record.id)" v-if="record.activity_mapping_status == 1">删除</a>
              <a @click="recoverGuideShoot(record.id)" v-if="record.activity_mapping_status == 3">恢复</a>
            </div>
          </a-table>
          <base-pagination
            :currentPage="dataPagination.current"
            :pageSize="dataPagination.pageSize"
            :total="dataPagination.total"
            @onChange="handleDataPaginationChange"
            @onShowSizeChange="handleDataPaginationChange"
          />
        </div>
      </div>
    </div>

    <!--查看样片-->
    <preview-modal
      :isAuth="isAuth"
      :isAutoplay="true"
      :previewType="previewType"
      :visible="previewVisible"
      :preview-src="previewSrc"
      @cancel="handlePreviewCancel"
    />

    <a-drawer
      title="选择活动导拍模板"
      placement="right"
      :width="600"
      :closable="true"
      :maskClosable="true"
      :visible="guideshootVisible"
      @close="onCloseDrawer"
    >
      <a-alert
        message="仅支持关联公开范围为 品牌和经销商的模版，查看模版的公开范围请前往 阿凡提总后台-导拍模版管理"
        type="info"
        style="margin-bottom: 10px"
      />
      <a-form-model ref="form" :model="form" v-bind="layout">
        <a-row :gutter="15">
          <a-col :span="15">
            <a-form-model-item label="模板名称">
              <a-input v-model="form.guideshoot_name" placeholder="请输入模板名称" allow-clear />
            </a-form-model-item>
          </a-col>
          <a-col :span="9">
            <a-form-model-item>
              <a-space>
                <a-button type="primary" @click="handleSearch">查询</a-button>
                <a-button @click="handleReset">重置</a-button>
                <a-dropdown>
                  <a-button type="primary">导入</a-button>
                  <a-menu slot="overlay">
                    <a-menu-item>
                      <a href="javascript:;" @click="handleVisibleUpload">上传导拍模板清单</a>
                    </a-menu-item>
                    <a-menu-item>
                      <a href="javascript:;" @click="handleDownloadTemplate">下载导入表格</a>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </a-space>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>

      <div>点击选择以下模板可关联到活动：</div>
      <a-table
        :loading="guideshootLoading"
        :columns="guideshootColumns"
        :data-source="guideshootDataList"
        :row-key="(record, index) => index"
        :pagination="false"
        :customRow="customRowClick"
      >
        <div slot="action" slot-scope="text, record">
          <a @click.stop="openSampleUrl(record.id)">查看样片</a>
        </div>
      </a-table>

      <base-pagination
        :currentPage="pagination.current"
        :pageSize="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </a-drawer>
    <a-modal v-model="visibleUpload" title="上传导拍模板" :footer="null">
      <a-spin :spinning="uploading">
        <a-upload-dragger
          name="file"
          accept=".XLSX, .XLS, .xlsx, .xls"
          :file-list="fileList"
          :custom-request="customRequest"
          @change="handleUploadChange"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" style="font-size: 60px; color: #00a0e9" />
          </p>
          <p class="ant-upload-text">点击或将文件拖拽到这里上传</p>
          <p class="ant-upload-hint">支持扩展名：.xlsx, .xls</p>
        </a-upload-dragger>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import previewModal from '@/components/PreviewModalVideo/PreviewModalVideo';
import dayjs from 'dayjs';
import jjtActivesAPI from '@/api/jjtActives';
import { downloadByUrl } from '@/utils';

export default {
  components: {
    previewModal,
  },
  data() {
    return {
      previewType: '',
      isAuth: false,
      activityId: this.$route.params.groupId,
      principalId: this.$route.query.principalId,
      detailInfo: {},
      loading: false,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
        },
        {
          title: '导拍模板名称',
          dataIndex: 'guideshoot_name',
        },
        {
          title: '车系',
          dataIndex: 'car_series_name',
        },
        {
          title: '分类',
          dataIndex: 'category_name',
        },
        {
          title: '上架状态',
          dataIndex: 'status',
          customRender(text) {
            return text == 1 ? '上架' : text == 2 ? '下架' : '-';
          },
        },
        {
          title: '活动关联状态',
          dataIndex: 'activity_mapping_status',
          customRender(text) {
            return text == 1 ? '待确认' : text == 2 ? '已确认' : text == 3 ? '已删除' : '-';
          },
        },
        {
          title: '上传时间',
          dataIndex: 'fhl_ctime',
        },
        {
          title: '添加时间',
          dataIndex: 'jjt_ctime',
        },
        {
          title: '操作',
          key: 'action',
          width: 100,
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      dataList: [],
      previewVisible: false,
      previewSrc: '',
      guideshootVisible: false,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      form: {},
      guideshootLoading: false,
      guideshootColumns: [
        {
          title: '模板名称',
          dataIndex: 'guideshoot_name',
        },
        {
          title: '操作',
          key: 'action',
          width: 100,
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      dataPagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      guideshootDataList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      customRowClick: (record) => ({
        on: {
          click: () => {
            this.guideshootVisible = false;
            this.bindGuideShoot(record.id);
          },
        },
      }),
      visibleUpload: false,
      fileList: [],
      uploading: false,
    };
  },
  created() {
    this.getActivityDetail();
    this.getTemplateList();
    this.getGuideShootList();
  },
  methods: {
    getRelationType(relation) {
      switch (relation) {
        case 'or':
          return '包含任意';
        case 'and':
          return '包含全部';
        default:
          return '';
      }
    },
    getNoteType(info) {
      console.log(info.note_key_types);
      const typeMap = {
        1: '标题',
        2: '话题',
        3: '正文',
      };
      return (
        info.note_key_types
          .split(',')
          .map((val) => typeMap[val])
          .join('/') + (info.note_keyword_relation === 'or' ? '包含任意' : '包含全部')
      );
    },
    getNoteKeyword(info) {
      return info.note_keyword;
    },
    handleVisibleUpload() {
      this.visibleUpload = !this.visibleUpload;
    },
    handleDownloadTemplate() {
      jjtActivesAPI
        .downloadCompetitionTemplate()
        .then((res) => {
          if (res.code) {
            downloadByUrl(res.data);
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    customRequest({ file }) {
      this.uploading = true;
      const formData = new FormData();
      formData.append('file', file);
      jjtActivesAPI
        .uploadCompetitionTemplate(formData, this.activityId)
        .then((res) => {
          if (res.code) {
            this.visibleUpload = false;
            this.$message.success(`已将${res.data}个导拍模板添加到活动！`);
            this.getActivityDetail();
            this.getTemplateList();
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        })
        .finally(() => {
          this.uploading = false;
        });
    },
    handleUploadChange() {},
    getActivityDetail() {
      this.loading = true;
      jjtActivesAPI.getActivityDetail(this.activityId).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          let _data = res.data;
          _data.start_time = dayjs(_data.start_time).format('YYYY-MM-DD');
          _data.end_time = dayjs(_data.end_time).format('YYYY-MM-DD');
          let arr = [];
          let content = '';
          try {
            arr = _data.platform.split(',');
          } catch {
            arr = [_data.platform];
          }

          arr.map((item, index) => {
            let symbols = arr.length > 0 ? (index == arr.length - 1 ? '' : '，') : '';

            if (item == 1) {
              content += '抖音' + symbols;
            } else if (item == 2) {
              content += '快手' + symbols;
            } else if (item == 3) {
              content += '懂车帝' + symbols;
            } else if (item == 4) {
              content += '视频号' + symbols;
            } else if (item == 5) {
              content += '小红书' + symbols;
            }
          });
          _data.platform_text = content;

          this.detailInfo = _data;
        } else {
          console.error(`获取详情失败${res}`);
        }
      });
    },
    getTemplateList() {
      this.loading = true;
      let params = {
        activity_id: this.activityId,
        page_num: this.dataPagination.current,
        page_size: this.dataPagination.pageSize,
      };
      this.$api.jjtActives.getActivityGuideShootList(params).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          this.dataList = res.data.list || [];
          this.dataPagination.total = res.data.total || 0;
        } else {
          this.dataList = [];
          this.dataPagination.total = 0;
          console.error(`获取详情失败${res}`);
        }
      });
    },
    handleDataPaginationChange(current, pageSize) {
      this.dataPagination.current = current;
      this.dataPagination.pageSize = pageSize;
      this.getTemplateList();
    },
    openSampleUrl(id) {
      this.$api.jjtActives.getPreviewList([id]).then((res) => {
        if (res.code === 200) {
          if (res.data.length > 0) {
            const url = res.data[0].originalSampleUrl;
            this.previewSrc = url;
            this.previewVisible = true;
            this.previewType = url.indexOf('image') === -1 ? 'video' : 'image';
            url.indexOf('huoshanvod') === -1 ? (this.isAuth = true) : (this.isAuth = false);
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handlePreviewCancel() {
      this.previewSrc = '';
      this.previewVisible = false;
    },
    deleteGuideShoot(id) {
      let that = this;
      this.$confirm({
        title: '确要从活动中删除该模板吗？',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          let params = {
            activity_id: that.activityId,
            template_id: id,
          };
          that.$api.jjtActives.deleteGuideShoot(params).then((res) => {
            if (res.code === 200) {
              that.$message.success('删除成功');
              that.getActivityDetail();
            } else {
              that.$message.error('删除失败');
            }
          });
        },
      });
    },
    recoverGuideShoot(id) {
      let params = {
        activity_id: this.activityId,
        template_id: id,
      };
      jjtActivesAPI.recoverGuideShoot(params).then((res) => {
        if (res.code === 200) {
          this.$message.success('恢复成功');
          this.getActivityDetail();
        } else {
          this.$message.error('恢复失败');
        }
      });
    },
    handleSearch() {
      this.pagination.current = 1;
      this.getGuideShootList();
    },
    handleReset() {
      this.form = {};
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getGuideShootList();
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getGuideShootList();
    },
    getGuideShootList() {
      this.guideshootLoading = true;
      let params = {
        guideshoot_name: this.form.guideshoot_name,
        principal_id: this.principalId,
        page_num: this.pagination.current,
        page_size: this.pagination.pageSize,
      };

      jjtActivesAPI.getGuideShootList(params).then((res) => {
        this.guideshootLoading = false;
        if (res.code == 200) {
          this.guideshootDataList = res.data.list;
          this.pagination.total = res.data.total;
        } else {
          this.guideshootDataList = [];
          this.pagination.total = 0;
          console.error(`获取模板列表失败${res}`);
        }
      });
    },
    bindGuideShoot(id) {
      let params = {
        activity_id: this.activityId,
        template_id: id,
      };

      jjtActivesAPI.bindGuideShoot(params).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message);
          this.getTemplateList();
        } else {
          this.$message.error(`绑定失败，${res.message}`);
        }
      });
    },
    onShowDrawer() {
      this.guideshootVisible = true;
    },
    onCloseDrawer() {
      this.guideshootVisible = false;
    },
  },
};
</script>

<style scoped lang="less">
.row {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.main-title {
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;

  .title {
    font-size: 18px;
    font-weight: bold;
    color: #000;
  }

  .time {
    span {
      font-weight: bold;
    }
  }
}

.content {
  .box {
    margin: 30px 0;
  }

  .col {
    margin-right: 30px;
  }

  .title {
    font-weight: bold;
    color: #000;

    .icon {
      font-size: 20px;
      color: #2ebdf2;
      cursor: pointer;
    }
  }

  .detail {
    margin-left: 30px;
  }

  .mr-20 {
    margin-right: 20px;
  }

  .table {
    margin-top: 20px;
  }
}
</style>
